import { useHeaderState } from 'context/header/header.context';
import { PendingInvitesBadge, PendingInvitesButton, PendingInvitesButtonText } from 'routes/all-agencies/styles';
import { openModalAction } from 'state/reducers/invites-modal-reducer';
import { useAppDispatch, useAppSelector } from 'state/state-hooks';
import styled from 'styled-components';

import BreadCrumbs from './breadcrumbs';

const Header: React.FC = () => {
  const { breadcrumbs, Icon } = useHeaderState();
  const { user } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  return (
    <Wrapper>
      <BreadCrumbs breadcrumbs={breadcrumbs} Icon={Icon} />
      <ActionsContainer>
        {user.attributes?.invites && user.attributes.invites.length > 0 && (
          <PendingInvitesButton
            data-testid='header__pending-invites-button'
            onClick={() => {
              dispatch(openModalAction());
            }}
          >
            <PendingInvitesBadge>
              <span>{user.attributes.invites.length}</span>
            </PendingInvitesBadge>
            <PendingInvitesButtonText>Pending Invites</PendingInvitesButtonText>
          </PendingInvitesButton>
        )}
      </ActionsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 78px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 78px);
  padding: 19px 30px 19px 20px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.silver100};
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 60px;
`;

export default Header;
