import { useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { CUser } from 'cognito.types';
import TextInput from 'components/form-elements/text-input/text-input';
import Modal from 'components/modal/modal';
import RenderModal from 'components/modal/render-modal';
import React, { useState } from 'react';
import { ErrorMessage } from 'routes/login/styles';
import { setTokenAction } from 'state/reducers/auth-reducer';
import { useAppDispatch } from 'state/state-hooks';
import { COMPLETE_REGISTRATION } from 'utils/gql';

import TermsAndConditions from '../terms-and-conditions/terms-and-conditions';

import { Button, CheckBoxWrapper, FormLabel, NewPasswordWrapper, SmallLogo } from './styles';

interface Props {
  user: CUser;
}

const NewPassword: React.FC<Props> = ({ user }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<string>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useAppDispatch();

  const [completeRegistration] = useMutation(COMPLETE_REGISTRATION);

  const showParams = user.challengeParam && user.challengeParam?.requiredAttributes.length > 0;
  const passwordsMatch = newPassword === confirmPassword;

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const checkPasswords = () => {
    if (!passwordsMatch) {
      setNewPasswordError('Passwords do not match');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setNewPasswordError('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      const u = (await Auth.completeNewPassword(
        user,
        newPassword,
        { family_name: lastName, given_name: firstName },
        { portal: 'agency' }
      )) as CUser;
      dispatch(setTokenAction({ idToken: u.signInUserSession.idToken.jwtToken, refreshToken: '' }));
      completeRegistration({
        variables: {
          acceptedTerms,
        },
      }).then(() => {
        setLoading(false);
        dispatch(setTokenAction({ idToken: u.signInUserSession.idToken.jwtToken, refreshToken: u.signInUserSession.refreshToken.token }));
      });
    } catch (err) {
      if (err instanceof Error) {
        setLoading(false);
        setError(err.message as string);
      }
    }
  };

  return (
    <>
      <NewPasswordWrapper data-testid='new-password__wrapper'>
        <SmallLogo />
        <form onSubmit={handleSubmit} style={{ width: 340 }} data-testid='new-password__form'>
          <FormLabel>Set up your Swell account.</FormLabel>
          <ErrorMessage>{error}</ErrorMessage>
          <TextInput
            label='New Password'
            testid='new-password__new-password-input'
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value);
              if (e.target.value === confirmPassword) setNewPasswordError(undefined);
            }}
            placeholder='Password'
            errorText={newPasswordError || ''}
            required
            showError={!!newPasswordError}
            width='100%'
            type='password'
          />
          <br />
          <TextInput
            label='Confirm New Password'
            testid='new-password__confirm-password-input'
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value);
              if (e.target.value === newPassword) setNewPasswordError(undefined);
            }}
            onBlur={checkPasswords}
            placeholder='Password'
            errorText={newPasswordError || ''}
            required
            showError={!!newPasswordError}
            width='100%'
            type='password'
          />
          <br />

          {showParams && (
            <>
              <TextInput
                label='First Name'
                testid='new-password__first-name-input'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                placeholder='First Name'
                errorText=''
                required
                showError={false}
                width='100%'
                type='text'
              />
              <br />

              <TextInput
                label='Last Name'
                testid='new-password__last-name-input'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                placeholder='Last Name'
                errorText=''
                required
                showError={false}
                width='100%'
                type='text'
              />
              <br />
              <CheckBoxWrapper>
                <input
                  type='checkbox'
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                  checked={acceptedTerms}
                  value={'acceptedTerms'}
                  data-testid='new-password__accept-terms-input'
                />
                <p>
                  I accept the
                  <button onClick={openModal} data-testid='new-password__open-modal-button'>
                    Terms of Service
                  </button>
                  and
                  <button onClick={openModal}>User License Agreement</button>
                </p>
              </CheckBoxWrapper>
            </>
          )}
          <Button
            type='submit'
            disabled={!firstName || !lastName || !NewPassword || !confirmPassword || !acceptedTerms || !passwordsMatch}
            data-testid='new-password__submit-button'
          >
            {loading ? 'Loading...' : 'Submit'}
          </Button>
        </form>
      </NewPasswordWrapper>
      <RenderModal>
        <Modal title='Terms and Conditions' open={modalOpen} onClose={/* istanbul ignore next */ () => setModalOpen(false)}>
          <TermsAndConditions />
        </Modal>
      </RenderModal>
    </>
  );
};

export default NewPassword;
